<template>
  <div
    class="bg-sonic-yellow/10 font-sub text-sonic-yellow flex items-center justify-center w-full px-2.5 py-3 gap-2"
    role="alert"
    aria-live="polite"
  >
    <IconsMegaphone class="h-6 w-6" />
    <span class="text-sm"
      >During the Whitelist Sale, it's first come, first served! Don’t miss your
      chance!</span
    >
  </div>
</template>
