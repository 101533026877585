<script lang="ts" setup>
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { mitt } from "~/lib/events";
import { useWalletsStore } from "~/stores/wallets";

const { $ttq: ttq } = useNuxtApp();
const { disconnect, shortAddress, isAuthenticated } = useWalletsStore();

function openConnectDialog() {
  mitt.emit("connect-wallet:open");
  ttq.track("ClickButton", {
    contents: [
      {
        content_id: "0001",
        content_type: "Sonic",
        content_name: "ClickButton",
      },
    ],
    value: "1",
    currency: "USD",
  });
}
</script>

<template>
  <div data-auth-drawer-mobile class="sm:hidden block">
    <Drawer direction="right" v-if="isAuthenticated">
      <DrawerTrigger>
        <Button class="sm:ml-0 ml-auto gap-2" variant="outline" size="sm">
          <IconsWallet class="h-6 w-6" />
          <span>{{ shortAddress }}</span>
        </Button>
      </DrawerTrigger>
      <DrawerContent
        direction="right"
        class="h-screen border-none rounded-none bg-[#111] hide-handle px-4"
      >
        <div class="flex flex-col justify-start items-start pb-6">
          <!-- Header -->
          <div
            class="flex justify-between items-center py-4 text-[#666666] w-full"
          >
            <div
              :data-authenticated="isAuthenticated"
              class="text-sm leading-none uppercase font-semibold opacity-0 data-[authenticated=true]:opacity-100"
            >
              My wallet
            </div>
            <DrawerClose
              class="rounded-sm disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-[#666666] focus:outline-none"
            >
              <IconsClose class="w-6 h-6" />
              <span class="sr-only">Close</span>
            </DrawerClose>
          </div>
          <template v-if="isAuthenticated">
            <!-- Auth button items -->
            <div class="flex flex-col w-full">
              <DrawerClose as-child>
                <NuxtLink
                  class="flex items-center justify-start gap-1 py-3"
                  to="/referrals"
                >
                  <IconsReferrals class="h-6 w-6" />
                  <span>My Referrals</span>
                </NuxtLink>
              </DrawerClose>
              <DrawerClose as-child>
                <NuxtLink
                  class="flex items-center justify-start gap-1 py-3"
                  to="/history"
                >
                  <IconsRecords class="h-6 w-6" />
                  <span>Purchase History</span>
                </NuxtLink>
              </DrawerClose>
              <DrawerClose as-child>
                <NuxtLink
                  class="flex items-center justify-start gap-1 py-3"
                  @click="disconnect"
                >
                  <IconsLogout class="h-6 w-6" />
                  <span>Disconnect</span>
                </NuxtLink>
              </DrawerClose>
            </div>
          </template>
        </div>
        <DrawerFooter>
          <DrawerClose>
            <Button variant="link"> Cancel </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
    <Button v-else class="sm:ml-0 ml-auto" @click="openConnectDialog">
      <div class="hidden sm:block">Connect Wallet</div>
      <div class="block sm:hidden">Connect</div>
    </Button>
  </div>
</template>
