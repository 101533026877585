<template>
  <main className="min-h-screen grid grid-rows-[auto_1fr]">
    <MoleculesNavbar />
    <slot />
    <MoleculesFooter />
  </main>
</template>

<script lang="ts" setup>
import { head } from "~/lib/site";
useHead(head);
</script>
