<script setup lang="ts">
const year = new Date().getFullYear();

const copyright = `©Copyright ${year} Sonic`;
</script>

<template>
  <footer
    class="flex flex-col items-center justify-center gap-4 sm:gap-8 bg-sonic-nav w-full sm:!py-14 !py-20 px-4"
  >
    <div class="w-full flex flex-col sm:gap-5 max-w-[1464px] mx-auto">
      <div
        class="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-14 w-full"
      >
        <div class="flex space-x-3 justify-start items-center">
          <MoleculesLogo />
          <div class="w-[1px] h-5 bg-sonic-disabled"></div>
          <a
            class="outline-none focus:outline-none cursor-pointer"
            target="_blnk"
            href="https://sonic.game"
            >sonic.game</a
          >
        </div>

        <!-- Links -->
        <div class="flex flex-col sm:flex-row gap-3 w-full sm:ml-auto sm:w-fit">
          <div class="text-sonic-disabled text-[10px] font-sub block sm:hidden">
            Links
          </div>
          <div
            class="flex flex-col sm:flex-row sm:space-x-6 sm:space-y-0 space-y-6 font-sans"
          >
            <NuxtLink
              to="/"
              class="outline-none focus:outline-none text-white hover:text-white/50 flex justify-between sm:justify-start uppercase font-semibold text-sm"
            >
              Buy Node
              <IconsArrowRight
                class="block text-sonic-disabled sm:hidden w-6 h-6"
              />
            </NuxtLink>
            <NuxtLink
              to="https://hyperfusedocs.sonic.game"
              class="outline-none focus:outline-none text-white hover:text-white/50 flex justify-between sm:justify-start uppercase font-semibold text-sm"
            >
              About Node
              <IconsArrowRight
                class="block text-sonic-disabled sm:hidden w-6 h-6"
              />
            </NuxtLink>
          </div>
        </div>

        <!-- Commnuity -->
        <div class="flex flex-col sm:hidden gap-3 w-full">
          <div class="text-sonic-disabled text-[10px] font-sub block sm:hidden">
            Community
          </div>
          <div class="flex gap-4 items-center justify-start">
            <!-- X -->
            <a
              href="https://x.com/SonicSVM"
              class="flex justify-center items-center outline-none focus:outline-none text-white bg-white/20 rounded-[6px] p-2"
            >
              <IconsX class="w-6 h-6" />
            </a>
            <!-- Discord -->
            <a
              href="https://discord.com/invite/joinmirrorworld"
              class="flex justify-center items-center outline-none focus:outline-none text-white bg-white/20 rounded-[6px] p-2"
            >
              <IconsDiscord class="w-6 h-6" />
            </a>
          </div>
        </div>

        <div
          class="w-full flex flex-col gap-4 sm:gap-4 items-center sm:hidden"
          data-mobile-copyright
        >
          <div class="relative w-full">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-white/20" />
            </div>
          </div>

          <p class="font-sub text-sonic-disabled text-xs">
            {{ copyright }}
          </p>
        </div>
      </div>

      <!-- Desktop Copyright -->
      <div
        data-desktop-copyright
        class="w-full flex flex-col gap-4 sm:gap-4 items-center hidden sm:flex"
      >
        <div class="relative w-full pb-10">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-white/20" />
          </div>
        </div>

        <div
          class="font-sub text-sonic-disabled text-xs flex justify-between items-center w-full"
        >
          <p>{{ copyright }}</p>

          <!-- Desktop Socials -->
          <div class="flex gap-4 items-center justify-start">
            <!-- X -->
            <a
              href="https://x.com/SonicSVM"
              class="flex justify-center items-center outline-none focus:outline-none text-white bg-white/20 rounded-[6px] p-2"
            >
              <IconsX class="w-6 h-6" />
            </a>
            <!-- Discord -->
            <a
              href="https://discord.com/invite/joinmirrorworld"
              class="flex justify-center items-center outline-none focus:outline-none text-white bg-white/20 rounded-[6px] p-2"
            >
              <IconsDiscord class="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
