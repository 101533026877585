<template>
  <div class="w-full sticky top-0 bg-sonic-nav z-30">
    <nav
      class="flex items-center justify-between py-4 w-full mx-auto px-4 sm:px-10 gap-4"
    >
      <NuxtLink to="/" class="outline-none focus:outline-none">
        <MoleculesLogo class="h-8" />
      </NuxtLink>

      <div class="justify-center gap-12 items-center sm:flex hidden">
        <NuxtLink
          to="/"
          class="text-base font-semibold hover:text-sonic-link-hover"
        >
          Buy Node
        </NuxtLink>
        <NuxtLink
          to="https://hyperfusedocs.sonic.game"
          target="_blank"
          class="text-base font-semibold hover:text-sonic-link-hover"
        >
          About Node
        </NuxtLink>
      </div>

      <div
        class="flex gap-4 items-center justify-center"
        v-if="isMobileViewport"
      >
        <template v-if="!isBeforeWhitelistStarted">
          <MoleculesMobileTopNavigationMenu />
        </template>
        <template v-else>
          <span />
        </template>
        <MoleculesMobileNavigation />
      </div>

      <template v-if="!isBeforeWhitelistStarted && !isMobileViewport">
        <DropdownMenu v-if="isAuthenticated" class="hidden sm:block">
          <DropdownMenuTrigger as-child>
            <Button class="sm:ml-0 gap-2" variant="outline" size="sm">
              <IconsWallet class="h-6 w-6" />
              <span>{{ shortAddress }}</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            side="bottom"
            align="end"
            class="bg-sonic-menu-background px-0 py-1 border-none"
          >
            <DropdownMenuItem as-child>
              <NuxtLink
                class="flex items-center justify-start gap-1 px-4 py-3"
                to="/referrals"
              >
                <IconsReferrals class="h-6 w-6" />
                <span>My Referrals</span>
              </NuxtLink>
            </DropdownMenuItem>
            <DropdownMenuItem as-child>
              <NuxtLink
                class="flex items-center justify-start gap-1 px-4 py-3"
                to="/history"
              >
                <IconsRecords class="h-6 w-6" />
                <span>Purchase History</span>
              </NuxtLink>
            </DropdownMenuItem>
            <DropdownMenuItem
              class="flex items-center justify-start gap-1 px-4 py-3"
              @click="disconnect"
            >
              <IconsLogout class="h-6 w-6" />
              <span>Disconnect</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <Button v-else class="sm:ml-0 ml-auto" @click="openConnectDialog">
          <div class="hidden sm:block">Connect Wallet</div>
          <div class="block sm:hidden">Connect</div>
        </Button>
      </template>
    </nav>

    <LazyMoleculesWalletConnectDialog />
    <LazyMoleculesWhitelistDialog />

    <!-- Banner -->
    <template v-if="isRaffleStageStarted && isBeforePublicSale">
      <MoleculesWhitelistBanner />
    </template>
  </div>
</template>

<script setup lang="ts">
import { mitt } from "~/lib/events";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { _useWalletsStore, pinia } from "~/stores/wallets";
import { useWhitelistTiers } from "~/services/queries/use-whitelist-tiers";
import { useRaffleStageSeason, useWhitelistSeason } from "~/lib/dates";
import { watchOnce } from "@vueuse/core";

const { $ttq: ttq } = useNuxtApp();

const { shortAddress, isAuthenticated, connected, disconnect } =
  _useWalletsStore(pinia);

const whitelistTiersQuery = useWhitelistTiers();

const {
  isBeforeWhitelistStarted,
  isActive: isWhitelistPeriodActive,
  isBeforePublicSale,
} = useWhitelistSeason();
const { isRaffleStageStarted } = useRaffleStageSeason();

const { isMobileViewport } = useMobileViewport();

watchOnce(
  [() => whitelistTiersQuery.data.value, () => connected],
  ([whitelistData, newConnected]) => {
    if (!!newConnected && whitelistData && whitelistData?.whitelist_enable) {
      console.debug("whitelist enabled. opening dialog");
    }
  }
);

function openConnectDialog() {
  mitt.emit("connect-wallet:open");
  ttq.track("ClickButton", {
    contents: [
      {
        content_id: "0001",
        content_type: "Sonic",
        content_name: "ClickButton",
      },
    ],
    value: "1",
    currency: "USD",
  });
}
</script>
