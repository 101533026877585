<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 8.985H13.015V4V3.985H13H6H5.985V4V20V20.015H6H18H18.015V20V9V8.985H18ZM15.985 16.015V17.985H8.015V16.015H15.985ZM15.985 12.015V13.985H8.015V12.015H15.985ZM4.015 21.985V2.015H13.9938L19.985 8.00621V21.985H4.015Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.03"
    />
  </svg>
</template>
