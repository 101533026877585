<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9419 5.52342C17.6473 4.91769 16.263 4.47747 14.8157 4.22681C14.638 4.54815 14.4304 4.98036 14.2872 5.32418C12.7487 5.09282 11.2245 5.09282 9.7143 5.32418C9.57116 4.98036 9.3588 4.54815 9.17947 4.22681C7.73067 4.47747 6.3448 4.91931 5.05016 5.52662C2.43887 9.47263 1.73099 13.3206 2.08493 17.114C3.81688 18.4073 5.49534 19.193 7.14548 19.7072C7.55291 19.1464 7.91628 18.5503 8.22931 17.9221C7.63313 17.6956 7.06211 17.416 6.52256 17.0915C6.6657 16.9854 6.80571 16.8746 6.94098 16.7605C10.2318 18.2997 13.8074 18.2997 17.0589 16.7605C17.1958 16.8746 17.3358 16.9854 17.4774 17.0915C16.9362 17.4176 16.3637 17.6972 15.7675 17.9238C16.0805 18.5503 16.4423 19.148 16.8513 19.7088C18.503 19.1946 20.183 18.409 21.915 17.114C22.3303 12.7165 21.2056 8.90386 18.9419 5.52342ZM8.67765 14.7811C7.68977 14.7811 6.87963 13.8589 6.87963 12.7358C6.87963 11.6127 7.67247 10.6889 8.67765 10.6889C9.68285 10.6889 10.493 11.6111 10.4757 12.7358C10.4772 13.8589 9.68285 14.7811 8.67765 14.7811ZM15.3223 14.7811C14.3344 14.7811 13.5243 13.8589 13.5243 12.7358C13.5243 11.6127 14.3171 10.6889 15.3223 10.6889C16.3275 10.6889 17.1376 11.6111 17.1203 12.7358C17.1203 13.8589 16.3275 14.7811 15.3223 14.7811Z"
      fill="currentColor"
    />
  </svg>
</template>
