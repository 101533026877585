<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1307 4.49634H19.7375L14.0424 11.0054L20.7422 19.8628H15.4963L11.3876 14.4908L6.68624 19.8628H4.07788L10.1693 12.9006L3.74219 4.49634H9.12123L12.8352 9.4065L17.1307 4.49634ZM16.2158 18.3025H17.6602L8.33636 5.97467H6.78632L16.2158 18.3025Z"
      fill="currentColor"
    />
  </svg>
</template>
