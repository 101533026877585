<template>
  <div data-nav-menu-mobile class="sm:hidden block">
    <Drawer direction="right">
      <DrawerTrigger>
        <Button
          class="!px-2.5 !py-2.4 outline-none"
          size="icon"
          variant="ghost"
        >
          <HamburgerMenuIcon />
        </Button>
      </DrawerTrigger>
      <DrawerContent
        direction="right"
        class="h-screen border-none rounded-none bg-[#111] hide-handle px-4"
      >
        <div class="flex flex-col justify-start items-start pb-6">
          <!-- Header -->
          <div
            class="flex justify-between items-center py-4 text-[#666666] w-full"
          >
            <div
              :data-authenticated="isAuthenticated"
              class="text-sm leading-none uppercase font-semibold opacity-0 data-[authenticated=true]:opacity-100"
            >
              Menu
            </div>
            <DrawerClose
              class="rounded-sm disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-[#666666] focus:outline-none"
            >
              <IconsClose class="w-6 h-6" />
              <span class="sr-only">Close</span>
            </DrawerClose>
          </div>
          <div class="flex flex-col gap-5 w-full">
            <DrawerClose as-child>
              <NuxtLink to="/" class="text-base font-semibold uppercase">
                Buy Node
              </NuxtLink>
            </DrawerClose>
            <DrawerClose as-child>
              <NuxtLink
                to="https://hyperfusedocs.sonic.game"
                target="_blank"
                class="text-base font-semibold uppercase"
              >
                About Node
              </NuxtLink>
            </DrawerClose>
          </div>
        </div>
        <DrawerFooter>
          <DrawerClose>
            <Button variant="link"> Cancel </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  </div>
</template>

<script lang="ts" setup>
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
} from "@/components/ui/drawer";

import { HamburgerMenuIcon } from "@radix-icons/vue";
import { useWalletsStore } from "~/stores/wallets";

const { disconnect, isAuthenticated } = useWalletsStore();
</script>
