<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
  >
    <path
      d="M16.5 9V7H20.5V9H16.5ZM17.7 16L14.5 13.6L15.7 12L18.9 14.4L17.7 16ZM15.7 4L14.5 2.4L17.7 0L18.9 1.6L15.7 4ZM3.5 15V11H0.5V5H6.5L11.5 2V14L6.5 11H5.5V15H3.5ZM9.5 10.45V5.55L7.05 7H2.5V9H7.05L9.5 10.45ZM12.5 11.35V4.65C12.95 5.05 13.3125 5.5375 13.5875 6.1125C13.8625 6.6875 14 7.31667 14 8C14 8.68333 13.8625 9.3125 13.5875 9.8875C13.3125 10.4625 12.95 10.95 12.5 11.35Z"
      fill="currentColor"
    />
  </svg>
</template>
